@import 'src/styles/variables.scss';

.WeightPullResults {
  flex:1;
  flex-direction:column;
  margin-bottom:20px;

  .Dropdown, .Input {
    flex:1;
    margin-bottom:20px;
  }
}