@import '../../../styles/variables.scss';

.ResultsTab {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:650px;

  .actions {
    display:flex;
    width:100%;
    margin-bottom:20px;

    .left, .right {
      display:flex;
      flex:1;
    }

    .left {
      z-index:9999;

      .dropdown {
        min-width:250px;
        flex:1;
        margin-bottom:0px;
        margin-right:10px;

        &.section {
          min-width:100px;
          max-width:100px;
        }
      }

      .reset {
        height:52px;
        border-radius:4px;
        background-color:color(grey, 100);
        color:color(grey, 600);
        border:1px solid color(grey, 400);

        &:hover {
          color:color(orange, 300);
          border:1px solid color(orange, 300);
        }
      }
    }

    .right {
      justify-content:flex-end;
    }
  }

  .tableWrap {
    flex:1;
  }
}

.ActionRenderer {
  position:relative;
  margin-top:4px;
  z-index:999999999999999999;
}

.downloadModalInner {
  display:flex;
  flex:1;
  flex-direction:column;
  padding:20px;

  .title {
    font-size:24px;
    font-weight:500;
    margin-bottom:20px;
  }

  .modalInner {
    flex:1;
  }

  .modalActions {
    margin-top:20px;
    display:flex;
    justify-content:flex-end;
  }
}

.StatusRenderer, .ResultRenderer {
  display:flex;
  align-items:center;
  height:100%;
  flex:1;

  .status {
    display:inline-block;
    height:20px;
    line-height:20px;
    padding:4px 12px;
    background-color:color(grey, 300);
    color:color(grey, 600);
    font-weight:500;
    text-transform:capitalize;
    border-radius:4px;

    &.pending, &.absent, &.excused, &.weighed {
      background-color:color(blue, 200);
      color:color(blue, 300);
    }

    &.complete, &.qualified, &.passed {
      background-color:color(green, 200);
      color:color(green, 300);
    }

    &.unknown, &.not_qualified, &.disqualified, &.failed, &.nq {
      background-color:color(red, 100);
      color:color(red, 200);
    }

    &.nq {
      text-transform:uppercase;
    }
  }
}

@media (max-width:1160px) {
  .ResultsTab {
    .actions {
      flex-direction:column;

      .left {
        flex-wrap:wrap;

        .dropdown {
          margin-bottom:10px;
        }
      }

      .right {
        margin-top:10px;
      }
    }
  }
}