@import '../../../styles/variables.scss';

.RegistrantsTab {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:650px;

  .actions {
    display:flex;
    flex-wrap:wrap;
    gap:16px;
    margin-bottom:20px;

    .left, .right {
      display:flex;
      align-items:center;
    }

    .right {
      justify-content:flex-end;
    }

    .left {
      flex:1 2 auto;
      flex-wrap:wrap;
      column-gap:16px;
      row-gap:8px;

      .textInput {
        flex:0 1 auto;
        min-width:250px;
      }

      .reset {
        height:52px;
        border-radius:4px;
        background-color:color(grey, 100);
        color:color(grey, 600);
        border:1px solid color(grey, 400);

        &:hover {
          color:color(orange, 300);
          border:1px solid color(orange, 300);
        }
      }
    }

    .options {
      position:relative;

      .label {
        height:40px;
        padding:0px 10px;
        display:flex;
        align-items:center;
        border:1px solid color(grey, 400);
        border-radius:4px;

        &:hover {
          cursor:pointer;
          background-color:color(orange, 100);
          border:1px solid color(orange, 200);
        }

        span {
          margin-left:10px;
          font-weight:500;
        }

        .icon {
          margin-left:10px;
          background-color:color(grey, 600);
          width:20px;
          height:20px;
          mask-position:center center;
          mask-repeat:no-repeat;
        }

        .contextHoriz {
          -webkit-mask-image:url("../../../assets/icons/contextHoriz.svg");
          mask-image:url("../../../assets/icons/contextHoriz.svg");
        }
      }

      .actionDrop {
        position:absolute;
        top:42px;
        right:0px;
        min-width:200px;
        background-color:color(grey, 100);
      }
    }
  }

  .tableWrap {
    flex:1;
  }
}

.ActionRenderer {
  position:relative;
  margin-top:4px;
  z-index:999999999999999999;
}

@media (max-width:810px) {
  .RegistrantsTab {
    .actions {

      .left {

        .textInput {
          flex:1;
          min-width:300px;
        }
      }
    }
  }
}
